<template>
  <div class="">

    <Modal
      ref="EditQuizName"
      id="EditQuizName"
      :title="`<span class='px-2'> ${title} </span>`"
      size="md">

      <div class="px-5">
        <form @submit.prevent="save()">
        <div class="row">
         
          <div class="col-12 ">
            <label for="" style="color: #73738d; font-size: 14px"
              >Quiz Name:</label
            >
                 <div class="d-flex quizell-type justify-content-center align-items-center">
        <input type="text" class="w-100 quizell-form-control px-2"  placeholder="Quiz Name"   v-model.trim="quizName"
  >
        
    </div>
           
            <span
              class="text-danger py-1"
              style="font-size: 12px"
              v-if="quizName==''"
              >Quiz Name field is required</span
            >
          </div>
       
        </div>
        <div class="d-flex mt-3 justify-content-center">
          <QuizellButton :isLoading="isLoading||loading" type="submit">{{ btnTitle }}</QuizellButton>
        </div>
        </form>
      </div>
    </Modal>
  </div>
</template>
<script>
import QuizellButton from "../Layout/Button.vue";
import Modal from "../Layout/Modal.vue";
import axios from 'axios'
export default {
  props: {
    title:{
      type:String,
      default:'Edit Quiz'
    },
    btnTitle:{
      type:String,
      default:'Edit'
    },
    loading:Boolean,
    isSave:Boolean
  },
  components: {
    QuizellButton,

  
    // FilterBox,
    Modal,
  },

  data() {
    return {
      isLoading:false,
      Quiz:{},
      quizName:''
    };
  },
  computed: {
 
  },
  methods: {
    OpenModal(quiz,isCreate=false){
      this.Quiz = {...quiz};
      if(isCreate)
      {
        this.quizName= quiz.name
      }
    else
      this.quizName= quiz.title
 this.$refs.EditQuizName.open();
    },
    CloseModal(){
 this.$refs.EditQuizName.close();
    },
   
   
   async save() {
   
    if(this.quizName=='')
    return
     this.isLoading=true;
     if(this.isSave)
      {
        this.Quiz.name=this.quizName
       await this.$emit('update',this.Quiz)
      }
      else
    try {
        let data = {
          _token: this.GetTokenFromMetaTag,
          title: this.quizName,
          quizId: this.Quiz.id,
        };
        const response = await axios.post(`/updateUserQuiz`, data);
        console.log("Responsee",response)
        if (response.status == 200) {
          this.CloseModal();
        //  this.resetQuizValues();
          this.$emit("UpdateDashboard");
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isLoading = false;
      }
      this.isLoading = false;
      }
    
  }
};
</script>
<style scoped>
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.loader {
  min-height: 40px;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.upload-image {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #ffa201;
  font-size: 14px;
  cursor: pointer;
}
.upload-image-disabled {
  color: #73738d;
  font-size: 14px;
}
.file-upload-option >>> .btn {
  background-color: #7a4fa3;
  padding: 6px 8px;
}
.file-upload-option >>> .active,
.file-upload-option >>> .btn:focus,
.file-upload-option >>> .btn:active {
  background-color: #4d1b7e !important;
}
.file-upload-option >>> .active:focus {
  box-shadow: 0 0 0 0.2rem #4d1b7e !important;
}
.custom-switch
  >>> .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}
.custom-switch
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}

.custom-control-input.radio:checked ~ .custom-control-label::before {
  background-color: #0397cc !important;
  color: #0397cc !important;
  border-color: #0397cc !important;
}
.quizell-type:focus-within {
    color: #495057;
  background-color: #fff;
  border-color: #f7f6f9;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
.quizell-form-control{
border: none;
height:48px;
flex: 1;
border-radius: 10px;
}
.quizell-form-control:focus-visible{
    border: none!important;
    outline: none!important;
}
.quizell-type{
    /* white */
transition: 0.3s ease all;
background: #FFFFFF;
/* greyLight */

border: 1px solid #D2D8E0;
border-radius: 8px;
}
</style>
